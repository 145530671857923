* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  color: #0f0e0e;
  font-family: 'Open Sans', sans-serif !important;
}