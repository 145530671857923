.sign-up-container{
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%) !important;
  position:fixed;
  top:0;
  left: 0;
  width:100%;
  height:100%;
}

.affiliates-container a,
a .join-affiliate-button{
  text-decoration: none;
}

.logo {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.sign-up-row {
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  text-align: center;
}

.sign-up-column {
  flex: 50%;
}

.sign-up-column.form {
  flex: 50%;
}

.call-to-action-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0px 50px;
  color: white;
}

.sign-up-form {
  font-size: 1rem;
  padding: 20px 40px;
  box-shadow: rgb(0 0 0 / 30%) 0px 40px 40px 40px;
  width: 90%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background: white;
}

.affiliate-card p {
  font-size: 0.8rem;
}

button.join-affiliate-button {
  font-size: 0.8rem;
  margin-bottom: 10px;
  margin-left: 10px;
}

button.join-affiliate-button.orange {
  background: rgba(219,138,222,1);
}

.form-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
}

.already-have-account-container {
  padding: 10px 0px 0px 10px;
  text-align: right;
  display: flex;
  flex-direction: column;
  float: left;
}

a {
  width: fit-content;
}

.sign-up-form form {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.input-div{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

input {
  padding: 5px;
  border-radius: 20px;
  border-color: rgb(0 0 0 / 12%);
  padding-left: 20px;
}

label {
  margin-left: 5px;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

h2.sign-up-text {
  padding-top: 40px;
  color: white;
}

button.form-button {
  border: none;
  border-radius: 20px;
  padding: 12px;
  background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%) !important;
  color: white;
}

.call-to-action h1, h2.sign-up-text{
  font-weight: bold;
  font-size: 2rem;
}

p.error-message{
  color: #ff0000;
  font-size: 1rem;
}

p.success-message{
  color: rgb(62, 253, 4);
  font-size: 1rem;
}

.call-to-action p{
  font-size: 1.5rem;
}

.right-div{
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 27%;
}

header{
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 50px;
}

.side-nav{
  background-color: rgba(219, 138, 222);  
  width: 20%;
  height: 100vh;
  position: fixed;
}

.side-nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-nav a:hover {
  color: rgba(2,0,36,1);
  text-decoration: none;
  font-size: 1.4rem;
}

.profile-icon p {
  font-size: 1.2rem;
  padding-top: 10px;
  padding-left: 5px;
}

.profile-image{
  width: 48px;
  height: 48px;
  background-color: #a28df8;
  margin: 0px 15px;
  border-radius: 50%;
}

.form-container a {
  color: #a28df8;
}

.help-icon p{
  width: 45px;
  height: 45px;
  border: solid black 3px;
  margin: 0px 15px;
  border-radius: 50%;
  text-align: center;
}

.help-icon p {
  margin: auto;
  padding-top: 7px;
  font-size: 1.2rem;
  font-weight: bold;
}

main.App {
  display: flex;
  flex-direction: row;
  /* overflow-x: hidden; */
}

.page-content{
  height: 85vh;
}

img.arrow-icon { 
  max-height: 1.5rem;
}

img.logo-style {
  max-height: 4rem;
}

header a, 
header a:hover, 
header a:focus, 
header a:active {
  text-decoration: none;
  color: inherit;
}

.search-container {
  width: 70%;
  overflow: auto;
}

.affiliate-input-search{
  border-radius: 20px 0px 0px 20px;
  width: 80%;
}

button.search-button {
  padding: 7px 20px 7px 7px;
  border: none;
  border-radius: 0px 20px 20px 0px;
  background: #E0E0E0;
}

.dashboard-container, 
.affiliate-card, 
.profile-card {
  background-color: rgba(219, 138, 222);  
  padding: 30px 50px;
  margin: 20px 50px;
  border-radius: 50px;
  color: black;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 20px 5px;
}

.affiliate-card, 
.profile-card{
  background-color: white;
}

button.close-button-welcome-announcement {
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-bottom: 10px;
  float: right;
}

button.start-search-button,
button.join-affiliate-button,
button.blue-button{
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 10px 5px;
}

button.start-search-button{
  background-color: #E0E0E0;
}

button.join-affiliate-button, 
button.blue-button{
  /* background-color: #030050; */
  background-color: black;
  color: white;
  font-weight: 600;
}

.affilaite-container-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.affiliate-logo{
  height: 60px;
}

.category-list li{
  padding-left: 10px;
  list-style: none;
}

.category-list ul.categories{
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.8rem;
}

.loader-container {
  display: flex;
  justify-content: center;
}

.category-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hamburger-menu img{
  height: 40px;
}

.hamburger-menu{
  display: none;
}

.filter-cotainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 70px;
  padding: 20px 0px;
}

.nav-section {
  border-bottom: 1px rgb(0 0 0 / 10%) solid;
  padding: 20px;
}

.side-nav button{
  margin: 20px;
}

.cancel-link {
  text-align: center;
}

button.update-button {
  padding-left: 50px;
  padding-right: 50px;
}

.me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll,
a.navbar-brand,
.navigation-bar{
  display: none;
}

button.navbar-toggler.collapsed, button.navbar-toggler {
  display: none;
}
.search-container.desktop {
  margin-left: 50px;
}

nav.navigation-bar.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: white !important;
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 850px) {
  .right-div {
    width: 1000%;
  }
  .sign-up-column, .sign-up-column.form {
    flex: 100%;
  }
  .side-nav{
    display: none;
  }
  .right-div {
    margin-left: 0px;
  }

  .hamburger-menu{
    display: block;
  }

  .call-to-action{
    display: none;
  }

  .form-container {
    justify-content: inherit;
  }

  .sign-up-form {
    padding: 40px 40px;
    border-radius: 50px;
  }

  .sign-up-text{
    padding: 20px;
  }

  h2.sign-up-text{
    font-size: 3rem;
  }
  
  .profile-icon,
  .help-icon {
    display: none;
  }

  .dashboard-container, .affiliate-card, .profile-card {
    padding: 20px;
    margin: 20px;
    border-radius: 30px;
  }
  
  .filter-cotainer {
    margin: 10px 20px;
    padding: 0px;
  }

  .filter-cotainer button.blue-button{
    padding: 10px;
    font-size: 12px;
    margin-top: 10px;
  }

  .page-content {
    height: 100%;
  } 

  .navigation-bar{
    display: block;
  }

  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll,
  a.navbar-brand {
    display: block;
  }

  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    max-width: none !important;
    padding: 20px 0px;
  }

  .search-container {
    margin: 10px 0px 20px 0px;
  }

  button.navbar-toggler.collapsed {
    display: block;
  }

  button.navbar-toggler {
    display: inline-block;
  }

  .search-container.desktop {
    display: none;
  }

  nav.navigation-bar.navbar.navbar-expand-lg.navbar-light.bg-light {
    background: linear-gradient(-145deg, rgba(219,138,222,1) 0%, rgba(246,191,159,1) 100%) !important;
  }
}